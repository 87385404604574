import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ReCAPTCHA from 'react-google-recaptcha';
import { MultiSelect, PrimaryBtn, TextInput } from '../Shared';
import { doPost } from '../../utils';

function ConsultWithUs() {
  const dispatch = useDispatch();
  const initialFormData = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    budget: '',
    city: '',
    captchaCheckPassed: false,
    errors: {},
  };
  const [form, updateForm] = useState({
    ...initialFormData,
    errors: {},
  });
  const [isApiProcessing, updateIsApiProcessing] = useState(false);

  const handleFormUpdate = (key, value) => {
    updateForm({ ...form, [key]: value });
  };

  const submitForm = async () => {
    if (!form.captchaCheckPassed) {
      updateForm({ ...form, errors: { ...form.errors, captcha: "Kindly accept I'm not a robot" } });
      return;
    }
    updateIsApiProcessing(true);
    try {
      const { data } = await doPost({ url: '/enquiry', body: form });
      updateForm(initialFormData);
      let config = {
        message: "Thanks for reaching out to us, we'll get back to you",
        type: 'success',
      };
      if (data.errors) {
        throw Error(data.msg);
      }
      if (form.budget === '< 50 lakhs') {
        config.message =
          'Thanks for reaching out. Unfortunately we are currently unable to accommodate projects below ₹50 lakhs';
        config.type = 'error';
        config.autoDismiss = false;
      }
      dispatch({
        type: 'DISPLAY_TOAST',
        payload: { ...config },
      });
      dispatch({ type: 'HIDE_CONSULT_WITH_US' });
    } catch (e) {
      if (e.errors) {
        updateForm({ ...form, errors: { ...e.errors } });
      }
    } finally {
      updateIsApiProcessing(false);
    }
  };

  const renderError = key => {
    return (
      form.errors[key] && (
        <span
          style={{
            display: 'flex',
            width: '300px',
            wordWrap: 'break-word',
          }}
          className="tw-text-red-300"
        >
          {form.errors[key]}
        </span>
      )
    );
  };
  return (
    <div className="tw-flex tw-flex-col tw-gap-5 tw-m-4">
      <div>
        <label>First Name</label>
        <TextInput
          classes="tw-w-full"
          onChange={value => handleFormUpdate('firstName', value)}
          value={form.firstName}
        />
        {renderError('firstName')}
      </div>
      <div>
        <label>Last Name</label>
        <TextInput
          classes="tw-w-full"
          onChange={value => handleFormUpdate('lastName', value)}
          value={form.lastName}
        />
        {renderError('lastName')}
      </div>
      <div>
        <label>Email</label>
        <TextInput
          classes="tw-w-full"
          onChange={value => handleFormUpdate('email', value)}
          value={form.email}
        />
        {renderError('email')}
      </div>
      <div>
        <label>Phone number</label>
        <TextInput
          classes="tw-w-full"
          onChange={value => handleFormUpdate('contactNumber', value)}
          value={form.contactNumber}
        />
        {renderError('contactNumber')}
      </div>
      <div>
        <label>Budget</label>
        <MultiSelect
          single
          searchInput={false}
          onSelect={option => handleFormUpdate('budget', option.value)}
          selectedItems={new Set([form.budget])}
          items={['< 50 lakhs', '50 to 75 lakhs', '75 lakhs to 1.5 Cr.', '1.5 Cr+'].map(item => ({
            label: item,
            value: item,
          }))}
        />
        {renderError('budget')}
      </div>
      <div>
        <label>City</label>
        <TextInput
          classes="tw-w-full"
          onChange={value => handleFormUpdate('city', value)}
          value={form.city}
        />
        {renderError('city')}
      </div>
      <div>
        <ReCAPTCHA
          sitekey="6LeKg5UaAAAAADsf8QPYrt0tmrY5dlxYyuPuFp1d"
          onChange={() => {
            updateForm({
              ...form,
              captchaCheckPassed: true,
              errors: { ...form.errors, captcha: null },
            });
          }}
        />
        {renderError('captcha')}
      </div>
      <PrimaryBtn
        className={`tw-mb-5${isApiProcessing}` ? 'tw-cursor-not-allowed' : ''}
        disabled={isApiProcessing}
        onClick={submitForm}
        text="Enquire"
      />
    </div>
  );
}

ConsultWithUs.propTypes = {};

ConsultWithUs.defaultProps = {};

export { ConsultWithUs };
